import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="September 2022"
      subnav="release-notes">
      <div id="September2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          September was a big month for Uniform, as we added several new pages
          to our{' '}
          <Link href="/guidelines/accessibility/design">
            Accessibility Guidelines section
          </Link>
          , including a{' '}
          <Link href="/guidelines/accessibility/audit/design">
            full audit of our components
          </Link>
          , which you can also view on specific component pages. We also made
          improvements to Uniform components across the board, by updating our
          color palette for increased color contrast for text, and gave our
          users the ability to resize text using browser settings.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                Pages for our accessibility audit and other{' '}
                <Link href="/guidelines/accessibility/design">
                  accessibility guidelines
                </Link>
                .
              </Added>
              <Added>
                Accessibility audit statuses to all components (
                <Link href="/components/action-bar/design#Accessibility">
                  see example
                </Link>
                ).
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.23.2"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Fixed>
                <Link href="/components/forms/select/code">
                  Select component
                </Link>{' '}
                options that should not have been editable.
              </Fixed>
              <Fixed>
                Several module components that were inadvertently retaining
                their focus styles after their options were deselected.
              </Fixed>
              <Improved>
                Our color palette to fix{' '}
                <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
                  accessibility issues
                </Link>{' '}
                due to color contrast ratios with text.
              </Improved>
              <Removed>
                A style that prevents users from changing the browser font size,
                which made Hudl{' '}
                <Link href="https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html">
                  inaccessible
                </Link>
              </Removed>{' '}
              to some low-vision users.
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.2.1"
              versionUrl="https://github.com/hudl/rn-uniform/releases"
            />
            <div className={styles.changesList}>
              <Fixed>
                <Link href="/components/buttons/button/code?reactnative">
                  Button component
                </Link>{' '}
                not respecting <code>isBlock</code> property.
              </Fixed>
              <Improved>
                Our color palette to fix{' '}
                <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
                  accessibility issues
                </Link>{' '}
                due to color contrast ratios with text.
              </Improved>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Improved>
                Our color palette to fix{' '}
                <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
                  accessibility issues
                </Link>{' '}
                due to color contrast ratios with text.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
